import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { SnipcartContext } from "gatsby-plugin-snipcart-advanced/context";

const Cart = () => {
  const { state } = useContext(SnipcartContext);
  const { cartQuantity } = state;

  return (
    <button
      className="button is-primary is-floating cart snipcart-checkout"
      aria-label="cart"
      value={cartQuantity}
    >
      <FontAwesomeIcon icon={faCartShopping} />
    </button>
  );
};

export default Cart;
