import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { getImage } from "gatsby-plugin-image";
import FullWidthImage from "../components/FullWidthImage";
import Gallery from "@browniebroke/gatsby-image-gallery";
import Cart from "../components/Cart";

const Availability = ({ status, stock, allowOutOfStock }) => {
  console.log(status, stock, allowOutOfStock);
  if (status === "AVAILABLE") {
    return (
      <div className="theme-body">
        {stock === 0 || stock === undefined
          ? allowOutOfStock
            ? "(na)bestelling mogelijk"
            : "uitverkocht"
          : "beschikbaar"}
      </div>
    );
  }
  if (status === "COMMING_SOON") {
    return <div className="theme-body">Binnenkort beschikbaar</div>;
  }
  return <div className="theme-body">Niet beschikbaar</div>;
};

const ProductPostTemplate = ({ data, id, images }) => {
  const [productData, setProductData] = useState({
    stock: 0,
    allowOutOfStock: false,
  });
  let [count, setCount] = useState(1);
  let [size, setSize] = useState("M");
  let [color, setColor] = useState(
    data.colors ? data.colors.split("|")[0] : ""
  );

  function incrementCount() {
    count = count + 1;
    setCount(count);
  }
  function decrementCount() {
    count = count <= 1 ? 1 : count - 1;
    setCount(count);
  }

  useEffect(() => {
    getProductdata();
  }, []);

  async function getProductdata() {
    const secret = `${process.env.GATSBY_SNIPCART_PRIVATE_KEY}:`;
    await fetch(`https://app.snipcart.com/api/products/${id}`, {
      headers: {
        Authorization: `Basic ${btoa(secret)}`,
        Accept: "application/json",
      },
    })
      .then((request) => request.json())
      .then((data) => {
        setProductData({
          stock: data.totalStock,
          allowOutOfStock: data.allowOutOfStockPurchases,
        });
      })
      .catch((err) => console.log(err));
  }

  const productImage = getImage(images[0].thumb);

  return (
    <section>
      <div
        className="columns mobile-switch-arrangement"
        style={{ margin: "0" }}
        id="main"
      >
        <div className="column is-5" style={{ backgroundColor: "#e3decc" }}>
          <div className="container content">
            <div className="columns" style={{ justifyContent: "flex-end" }}>
              <div className="column is-8">
                <Gallery
                  images={images}
                  colWidth="100"
                  mdColWidth="100"
                  rowMargin="0"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="column is-7" style={{ backgroundColor: "#801c38" }}>
          <div className="container content sticky">
            <div className="columns">
              <div className="column is-10">
                <div className="section">
                  <h1 className="title is-size-2 has-text-white">
                    {data.name}
                  </h1>
                  <hr className="solid" />
                  <div className="is-flex add-to-cart mobile-column">
                    <h2 className="title is-size-2 has-text-weight-bold is-bold-light">
                      €{data.price.toFixed(2)}
                    </h2>

                    <div className="counter">
                      <span
                        role="button"
                        onClick={decrementCount}
                        onKeyDown={decrementCount}
                        className="minus button"
                        disabled={
                          (productData.stock === 0 &&
                            !productData.allowOutOfStock) ||
                          data.status !== "AVAILABLE"
                        }
                      >
                        -
                      </span>
                      <input
                        type="text"
                        onChange={(e) => setCount(e.target.value)}
                        value={count}
                        disabled={
                          (productData.stock === 0 &&
                            !productData.allowOutOfStock) ||
                          data.status !== "AVAILABLE"
                        }
                      />
                      <span
                        role="button"
                        onClick={incrementCount}
                        onKeyUp={incrementCount}
                        className="plus button"
                        disabled={
                          (productData.stock === 0 &&
                            !productData.allowOutOfStock) ||
                          data.status !== "AVAILABLE"
                        }
                      >
                        +
                      </span>
                    </div>

                    <button
                      className="snipcart-add-item button is-primary"
                      data-item-id={id}
                      data-item-price={data.price.toFixed(2)}
                      data-item-description={data.description}
                      data-item-name={data.name}
                      data-item-image={
                        images && productImage.images.fallback.src
                      }
                      data-item-quantity={count}
                      // data-item-max-quantity={
                      //   productData.allowOutOfStock ? 99 : productData.stock
                      // }
                      data-item-custom1-name={data.sizes === "" ? null : "Size"}
                      data-item-custom1-options={
                        data.sizes === "" ? null : data.sizes
                      }
                      data-item-custom1-value={data.sizes === "" ? null : size}
                      data-item-custom2-name={
                        data.colors === "" ? null : "Color"
                      }
                      data-item-custom2-options={
                        data.colors === "" ? null : data.colors
                      }
                      data-item-custom2-value={data.colors === "" ? null : size}
                      disabled={
                        (productData.stock === 0 &&
                          !productData.allowOutOfStock) ||
                        data.status !== "AVAILABLE"
                      }
                    >
                      {productData.allowOutOfStock ? "Bestel!" : "Koop nu!"}
                    </button>
                  </div>

                  <div className="is-flex add-to-cart mobile-column mt-4">
                    {data.sizes !== "" && (
                      <label className="select">
                        Maat:
                        <select
                          disabled={
                            (productData.stock === 0 &&
                              !productData.allowOutOfStock) ||
                            data.status !== "AVAILABLE"
                          }
                          name="size"
                          id="size"
                          value={size}
                          onChange={(e) => setSize(e.target.value)}
                        >
                          {data.sizes.split("|").map((item, i) => (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </label>
                    )}

                    {data.colors !== "" && (
                      <label className="select">
                        Kleur:
                        <select
                          disabled={
                            (productData.stock === 0 &&
                              !productData.allowOutOfStock) ||
                            data.status !== "AVAILABLE"
                          }
                          name="color"
                          id="color"
                          value={color}
                          onChange={(e) => setColor(e.target.value)}
                        >
                          {data.colors.split("|").map((item, i) => (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </label>
                    )}
                  </div>
                  <hr className="solid" />
                  <Availability
                    status={data.status}
                    stock={productData.stock}
                    allowOutOfStock={productData.allowOutOfStock}
                  />
                  <h2 className="title is-size-3">BESCHRIJVING</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: data.description }}
                    className="theme-body"
                  />
                  {productData.allowOutOfStock &&
                    data.status === "AVAILABLE" && (
                      <div className="theme-body">
                        <br /> Het betreft hier een artikel dat u kunt
                        aanschaffen op bestelling. Dit betekent dat het product
                        nog geproduceerd/besteld moet worden en dus niet per
                        direct beschikbaar is. De levertijd is doorgaans 6-8
                        weken. Voor meer vragen, neem contact op met de
                        lustrumcommissie.
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ProductPost = ({ data }) => {
  const { markdownRemark: post } = data;
  const heroImage = getImage(
    post.frontmatter.images[0].image.childImageSharp.full
  );
  const images = post.frontmatter.images.map(
    (node) => node.image.childImageSharp
  );

  return (
    <Layout>
      <Helmet titleTemplate="%s | Winkel">
        <title>{`${post.frontmatter.name}`}</title>
        <meta name="description" content={`${post.frontmatter.description}`} />
      </Helmet>
      <FullWidthImage
        img={heroImage}
        title={post.frontmatter.name}
        subheading={"€" + post.frontmatter.price.toFixed(2)}
        height={"50vh"}
        color="#E8E100"
        darken={true}
        maxFont={450}
        imgPosition={"center"}
        removeMobileText={true}
      />

      <Cart />

      <ProductPostTemplate
        data={post.frontmatter}
        id={post.frontmatter.productid}
        images={images}
      />
    </Layout>
  );
};

ProductPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default ProductPost;

export const pageQuery = graphql`
  query ProductPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        name
        description
        price
        productid
        status
        sizes
        colors
        images {
          image {
            childImageSharp {
              thumb: gatsbyImageData(width: 500, height: 500)
              full: gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
